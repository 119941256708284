export const levels =  [
    {
      "level_index": 0,
      "level_name": "沙滩",
      "level_desc": "XXX",
      "level_user_score": 1590,
      "level_user_difficulty_score": 0,
      "level_user_completed_progress": 0,
      "is_completed": true,
      "_id": "66dd1584975f9152cf178f60"
    },
    {
      "level_index": 1,
      "level_name": "沙滩2",
      "level_desc": "XXX",
      "level_user_score": 0,
      "level_user_difficulty_score": 0,
      "level_user_completed_progress": 0,
      "is_completed": false,
      "_id": "66dd1584975f9152cf178f61"
    },
    {
      "level_index": 2,
      "level_name": "沙滩3",
      "level_desc": "XXX",
      "level_user_score": 0,
      "level_user_difficulty_score": 0,
      "level_user_completed_progress": 0,
      "is_completed": false,
      "_id": "66dd1584975f9152cf178f62"
    },
    {
      "level_index": 3,
      "level_name": "沙滩4",
      "level_desc": "XXX",
      "level_user_score": 0,
      "level_user_difficulty_score": 0,
      "level_user_completed_progress": 0,
      "is_completed": false,
      "_id": "66dd1584975f9152cf178f63"
    },
    {
      "level_index": 4,
      "level_name": "沙滩5",
      "level_desc": "XXX",
      "level_user_score": 0,
      "level_user_difficulty_score": 0,
      "level_user_completed_progress": 0,
      "is_completed": false,
      "_id": "66dd1584975f9152cf178f64"
    },
    {
      "level_index": 5,
      "level_name": "沙滩6",
      "level_desc": "XXX",
      "level_user_score": 0,
      "level_user_difficulty_score": 0,
      "level_user_completed_progress": 0,
      "is_completed": false,
      "_id": "66dd1584975f9152cf178f65"
    },
    {
      "level_index": 6,
      "level_name": "沙滩7",
      "level_desc": "XXX",
      "level_user_score": 0,
      "level_user_difficulty_score": 0,
      "level_user_completed_progress": 0,
      "is_completed": false,
      "_id": "66dd1584975f9152cf178f66"
    },
    {
      "level_index": 7,
      "level_name": "沙滩8",
      "level_desc": "XXX",
      "level_user_score": 0,
      "level_user_difficulty_score": 0,
      "level_user_completed_progress": 0,
      "is_completed": false,
      "_id": "66dd1584975f9152cf178f67"
    },
    {
      "level_index": 8,
      "level_name": "沙滩9",
      "level_desc": "XXX",
      "level_user_score": 0,
      "level_user_difficulty_score": 0,
      "level_user_completed_progress": 0,
      "is_completed": false,
      "_id": "66dd1584975f9152cf178f68"
    },
    {
      "level_index": 9,
      "level_name": "沙滩10",
      "level_desc": "XXX",
      "level_user_score": 0,
      "level_user_difficulty_score": 0,
      "level_user_completed_progress": 0,
      "is_completed": false,
      "_id": "66dd1584975f9152cf178f69"
    },
    {
      "level_index": 10,
      "level_name": "沙滩11",
      "level_desc": "XXX",
      "level_user_score": 0,
      "level_user_difficulty_score": 0,
      "level_user_completed_progress": 0,
      "is_completed": false,
      "_id": "66dd1584975f9152cf178f6a"
    },
    {
      "level_index": 11,
      "level_name": "沙滩12",
      "level_desc": "XXX",
      "level_user_score": 0,
      "level_user_difficulty_score": 0,
      "level_user_completed_progress": 0,
      "is_completed": false,
      "_id": "66dd1584975f9152cf178f6b"
    },
    {
      "level_index": 12,
      "level_name": "沙滩13",
      "level_desc": "XXX",
      "level_user_score": 0,
      "level_user_difficulty_score": 0,
      "level_user_completed_progress": 0,
      "is_completed": false,
      "_id": "66dd1584975f9152cf178f6c"
    },
    {
      "level_index": 13,
      "level_name": "沙滩14",
      "level_desc": "XXX",
      "level_user_score": 0,
      "level_user_difficulty_score": 0,
      "level_user_completed_progress": 0,
      "is_completed": false,
      "_id": "66dd1584975f9152cf178f6d"
    },
    {
      "level_index": 14,
      "level_name": "沙滩15",
      "level_desc": "XXX",
      "level_user_score": 0,
      "level_user_difficulty_score": 0,
      "level_user_completed_progress": 0,
      "is_completed": false,
      "_id": "66dd1584975f9152cf178f6e"
    }
  ]

  export const leaderBoardAllData = {"eventType":"getLeaderBoardAll","eventData":{"list":[{"score":"13990","user":{"uid":"6719ef02b810634bb710e5e6","username":"Laww","level_last_completed_index":14,"remaining_revive_coins":0,"address":"2CoTtLGHF7UBxVjkJRe2opCyUcw8uW9rzNYVfNaWVNA96YUsQ3"}},{"score":"2730","user":{"uid":"66dd1584975f9152cf178f5f","username":"Cook","level_last_completed_index":1,"remaining_revive_coins":95,"address":"2mjPvsbZdyrvfXi5WX1nNw6QhQskoKqW5QbZzd8FFSpusv2Hpd"}},{"score":"2510","user":{"uid":"672055e84300602ac291027e","username":"Leong***","level_last_completed_index":0,"remaining_revive_coins":2,"address":"26uQJcdDBwgFrpjmH6BtRAKgJwGZJp79tss5Nyf34BxZmkWAEc"}},{"score":"1998","user":{"uid":"dc11df34-c6cf-426d-9144-dbd8ece02958","username":"Jack3","level_last_completed_index":11,"remaining_revive_coins":87,"address":"Address3"}},{"score":"1960","user":{"uid":"328cf291-4156-48c5-b400-7a6769f9b2f3","username":"Jack59","level_last_completed_index":10,"remaining_revive_coins":26,"address":"Address59"}},{"score":"1959","user":{"uid":"c6c94bee-eba6-4479-9061-f199b8b5e59b","username":"Jack55","level_last_completed_index":8,"remaining_revive_coins":77,"address":"Address55"}},{"score":"1955","user":{"uid":"40cc7c5f-459d-4709-b69a-cf4cbee59cff","username":"Jack32","level_last_completed_index":13,"remaining_revive_coins":92,"address":"Address32"}},{"score":"1921","user":{"uid":"f3196cf9-37a3-4d4e-a64f-37495a197ff8","username":"Jack67","level_last_completed_index":2,"remaining_revive_coins":57,"address":"Address67"}},{"score":"1884","user":{"uid":"63410ba8-2c94-4e6b-8650-2ec32b09faf6","username":"Jack36","level_last_completed_index":3,"remaining_revive_coins":44,"address":"Address36"}},{"score":"1861","user":{"uid":"9182d2b6-7df8-4248-a971-edb6410df89a","username":"Jack4","level_last_completed_index":9,"remaining_revive_coins":83,"address":"Address4"}}],"total":114,"user":{"rank":null,"score":"0","level_last_completed_index":0}}}

  export const itemConfigData = {
    eventType: "getItemConfig",
    eventData: {
      msg: "ok",
      list: [
        {
          id: "1",
          amount: "2",
          amount_coins: "1",
          unity: "ELF",
          chain_type: "tDVV",
        },
        {
          id: "2",
          amount: "100",
          amount_coins: "10",
          unity: "ELF",
          chain_type: "tDVV",
        },
        {
          id: "3",
          amount: "150",
          amount_coins: "15",
          unity: "ELF",
          chain_type: "tDVV",
        }
      ],
    }
  };