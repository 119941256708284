import { Button } from "./ui/button"
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./ui/sheet"
import React from "react";

type ActionsProps = {
    children: React.ReactNode[]
}

export default function Actions({children}: ActionsProps) {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline">Mod</Button>
      </SheetTrigger>
      <SheetContent side="bottom">
        <SheetHeader>
          <SheetTitle>Mod</SheetTitle>
          <SheetDescription>Modify data</SheetDescription>
        </SheetHeader>
        <div className="grid gap-4 py-4">
            {children}
        </div>
      </SheetContent>
    </Sheet>
  )
}